<template>
  <div class="person_infor">
    <div class="login_top">
      <!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
      <div class="border_box" @click="person">
        <div class="login_title" :class="is_show == 1 ? 'showColor' : ''">
          {{ $t("title.personInfor") }}
        </div>
        <div :class="is_show == 1 ? 'border' : ''"></div>
      </div>
      <div class="border_box" @click="company">
        <div class="login_title" :class="is_show == 2 ? 'showColor' : ''">
          {{ $t("title.companyInfor") }}
        </div>
        <div :class="is_show == 2 ? 'border' : ''"></div>
      </div>
    </div>
    <!-- 个人信息 -->
    <div class="infor" v-show="is_show == 1">
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("my.headPortrait") }}
        </div>
        <div class="infor_item_right">
          <el-upload
            ref="uploadGR"
            :show-file-list="false"
            :action="uploadImgUrl"
            list-type="picture-card"
            :limit="1"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-progress="handleProgress"
            v-loading="loadingGR"
          >
            <div
              class="upload_img"
              @mouseenter="mouseenterClick"
              @mouseleave="mouseleaveClick"
            >
              <img :src="headImg" v-if="headImg" />
              <span
                v-if="headImg && isShowDeleteIcon"
                class="el-upload-action"
                @click.stop="handleRemove()"
              >
                <i class="el-icon-delete"></i>
              </span>
              <img
                src="../../../assets/person/upload_img.png"
                v-if="!headImg"
              />
            </div>
            <!-- <div slot="tip" class="el-upload__tip">{{$t('uploadPhoto')}}</div> -->
          </el-upload>
        </div>
      </div>

      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("my.nickName") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              clearable
              v-model="user_nickname"
              :placeholder="$t('my.inputNickName')"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("my.sex") }}
        </div>
        <div class="infor_item_right">
          <el-radio-group v-model="sex" fill="#F39800" text-color="#F39800">
            <el-radio :label="0">{{ $t("my.private") }}</el-radio>
            <el-radio :label="1">{{ $t("other.man") }}</el-radio>
            <el-radio :label="2">{{ $t("other.women") }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("my.birth") }}
        </div>
        <div class="infor_item_right">
          <el-date-picker
            :picker-options="pickerDate"
            v-model="birthday"
            type="date"
            :title="$t('my.selectBirth')"
          >
          </el-date-picker>
        </div>
      </div>
      <!-- 增加的 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("wechatID") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              clearable
              v-model="wechat"
              :placeholder="$t('inputWechatId')"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="infor_item">
        <div class="infor_item_left">WhatsApp</div>
        <div class="infor_item_right">
          <div class="whatsApp">
            <!-- <div class="dropdown">
							<el-dropdown @command="areaCodeClick">
								<span class="el-dropdown-link">
									{{ whats_code }}<i class="el-icon-caret-bottom el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="(item, index) in areaCodeList" :key="index">
										{{ item }}
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div> -->
            <div class="whatsapp_input">
              <input
                type="number"
                v-model="whatsApp"
                :placeholder="$t('inputWhatsApp')"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="infor_item">
				<div class="infor_item_left">
					{{ $t('email') }}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="user_email" :placeholder="$t('login.inputEmail')"></el-input>
					</div>
				</div>
			</div> -->

      <!-- <div class="infor_item">
				<div class="infor_item_left">
					{{$t('code')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="code" :placeholder="$t('inputEmailCode')"></el-input>
					</div>
				</div>
			</div> -->

      <!-- <div class="infor_item">
				<div class="infor_item_left">
					{{ $t('contacts') }}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="contacts" :placeholder="$t('inputContacts')"></el-input>
					</div>
				</div>
			</div> -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("address") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <!-- @change="cascaderChangeClick($event,1)" -->
            <el-cascader
              v-model="areaVal"
              :options="countryList"
              clearable
              :placeholder="$t('other.selectCity')"
            >
            </el-cascader>
          </div>
        </div>
      </div>

      <!-- 
			<div class="infor_item">
				<div class="infor_item_left">
					{{ $t('PersonalIntroduction') }}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="geren_desc" :placeholder="$t('inputPersonalIntroduction')"
							maxlength="18" type="textarea" style="width:800px">
						</el-input>
					</div>
				</div>
			</div> -->

      <div class="info_item" @click="saveClick">
        <div class="save">{{ $t("my.save") }}</div>
      </div>
    </div>

    <!-- 公司信息 -->
    <div class="infor" v-show="is_show == 2">
      <!-- 当前状态 -->
      <div class="status_box" v-if="shenheStatus != 2 && shenheStatus != 0">
        <div class="status_box_left">
          <div style="margin-bottom: 8px">
            <span class="status_title">当前状态 : </span>
            <span class="now_status">
              <span class="ing" v-if="shenheStatus == 1">审核中</span>
              <span class="error" v-if="shenheStatus == 3">审核失败</span>
            </span>
          </div>
          <div v-if="shenheStatus == 3">
            {{ companyInforList.check_remark }}
          </div>
        </div>

        <div class="status_btn" @click="submitClick()" v-if="shenheStatus == 3">
          <div class="submit">{{ $t("my.submitAgain") }}</div>
        </div>
      </div>
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("companyLogo") }}
        </div>
        <div class="infor_item_right">
          <el-upload
            ref="upload"
            :show-file-list="false"
            :action="uploadImgUrl"
            list-type="picture-card"
            :limit="1"
            :on-success="handleAvatarSuccess2"
            :before-upload="beforeAvatarUpload"
            v-loading="loadingGS"
            :on-progress="handleProgress2"
          >
            <div
              class="upload_img"
              @mouseenter="mouseenterClick"
              @mouseleave="mouseleaveClick"
            >
              <img :src="company_logo_url" v-if="company_logo_url" />
              <span
                v-if="company_logo_url && isShowDeleteIcon"
                class="el-upload-action"
                @click.stop="handleRemove2()"
              >
                <i class="el-icon-delete"></i>
              </span>
              <img
                src="../../../assets/person/upload_img.png"
                v-if="!company_logo_url"
              />
            </div>
            <!-- <div slot="tip" class="el-upload__tip">{{ $t('uploadLogo') }}</div> -->
          </el-upload>
        </div>
      </div>
      <!-- 公司名称 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("companyName") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              clearable
              v-model="company_name"
              :placeholder="$t('inputCompanyName')"
              max="50"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- 公司税号 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("companyTaxNumber") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              clearable
              v-model="company_tax_number"
              :placeholder="$t('inputCompanyTaxNumber')"
              max="50"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- 联系人 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("contacts") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              clearable
              v-model="contacts"
              :placeholder="$t('inputContacts')"
            ></el-input>
          </div>
        </div>
      </div>

      <!-- 手机号 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("mobile") }}
        </div>
        <div class="infor_item_right">
          <div class="whatsApp">
            <div class="dropdown">
              <el-dropdown @command="areaCodeClick2">
                <span class="el-dropdown-link">
                  {{ mobile_code
                  }}<i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="item"
                    v-for="(item, index) in areaCodeList"
                    :key="index"
                  >
                    {{ item }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="whatsapp_input">
              <input
                type="number"
                v-model="mobile"
                :placeholder="$t('login.inputPhone')"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 邮箱号 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("email") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="user_email"
              :placeholder="$t('login.inputEmail')"
            >
            </el-input>
          </div>
        </div>
        <!-- <div class="code">
					<div class="code1" @click="getCode" v-if="!isGetCode">{{ $t('login.getCode') }}</div>
					<div class="code2" v-else>{{ $t('login.emailSendAgain') }}</div>
				</div> -->
      </div>

      <!-- 经纪人执照号 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("brokerLicenseNumber") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="broker_license_number"
              :placeholder="$t('inputBrokerLicenseNumber')"
            >
            </el-input>
          </div>
        </div>
      </div>

      <!-- 公司地址 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("companyAddress") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-cascader
              v-model="company_address"
              :options="countryList"
              clearable
              :placeholder="$t('inputCompanyAddress')"
              @change="cascaderChangeClick($event, 1)"
            >
            </el-cascader>
            <!-- <el-input type="text" clearable v-model="agent_type" :placeholder="$t('inputAgentType')">
						</el-input> -->
          </div>
        </div>
      </div>

      <!-- <div class="infor_item">
				<div class="infor_item_left">
					{{ $t('companyAddress') }}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input type="text" clearable v-model="company_address" :placeholder="$t('inputCompanyAddress')">
						</el-input>
					</div>
				</div>
			</div> -->

      <!-- 代理类型 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("agentType") }}
        </div>
        <div class="infor_item_right"></div>
        <div class="infor_item_right">
          <div class="input">
            <el-select
              v-model="agent_type"
              :placeholder="$t('inputAgentType')"
              clearable
              @change="change"
              ref="type"
            >
              <el-option
                v-for="item in agent_type_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-input type="text" clearable v-model="agent_type" :placeholder="$t('inputAgentType')">
						</el-input> -->
          </div>
        </div>
      </div>

      <!-- WhatsApp -->
      <div class="infor_item">
        <div class="infor_item_left">WhatsApp</div>
        <div class="infor_item_right">
          <div class="whatsApp">
            <div class="whatsapp_input">
              <input
                type="text"
                v-model="whats_App"
                :placeholder="$t('inputWhatsApp')"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 营业时间 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("businessHours") }}
        </div>
        <div class="infor_item_right">
          <!-- <el-time-picker is-range arrow-control v-model="business_hours" range-separator="至" start-placeholder="开始时间"
						end-placeholder="结束时间" placeholder="选择时间范围" style="width:800px;">
					</el-time-picker> -->
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="business_hours"
              :placeholder="$t('inputBusinessHours')"
            >
            </el-input>
          </div>
        </div>
      </div>

      <!-- web -->
      <div class="infor_item">
        <div class="infor_item_left">Web</div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="Web_value"
              :placeholder="$t('inputWeb')"
            >
            </el-input>
          </div>
        </div>
      </div>

      <!-- Skype -->
      <div class="infor_item">
        <div class="infor_item_left">Skype</div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="Skype_value"
              :placeholder="$t('inputSkype')"
            >
            </el-input>
          </div>
        </div>
      </div>

      <!-- Facebook -->
      <div class="infor_item">
        <div class="infor_item_left">Facebook</div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="Facebook_value"
              :placeholder="$t('inputFacebook')"
            >
            </el-input>
          </div>
        </div>
      </div>

        <!-- Twitter -->
        <div class="infor_item">
        <div class="infor_item_left">Twitter</div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="Twitter_value"
              :placeholder="$t('inputTwitter')"
            >
            </el-input>
          </div>
        </div>
      </div>

      <!-- Instagram -->
      <div class="infor_item">
        <div class="infor_item_left">Instagram</div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="ins_value"
              :placeholder="$t('inputInstagram')"
            >
            </el-input>
          </div>
        </div>
      </div>

      <!-- Linkedin -->
      <div class="infor_item">
        <div class="infor_item_left">Linkedin</div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              type="text"
              clearable
              v-model="Linkedin_value"
              :placeholder="$t('inputLinkedin')"
            >
            </el-input>
          </div>
        </div>
      </div>

      <!-- 公司简介 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("aboutCompany") }}
        </div>
        <div class="infor_item_right">
          <div class="input">
            <el-input
              clearable
              style="width: 800px"
              v-model="company_desc"
              type="textarea"
              :placeholder="$t('pleaseEnterCompanyProfile')"
              :autosize="{ minRows: 8, maxRows: 20 }"
            >
            </el-input>
          </div>
        </div>
      </div>

      <!-- 公司简介 -->
      <div class="infor_item">
        <div class="infor_item_left">
          {{ $t("aboutCompany") }}
        </div>
        <div class="jianjie_box">
          <!-- <el-upload :action="uploadImgUrl" list-type="picture-card" :limit="1" :on-success="handleAvatarSuccess3"
						:before-upload="beforeAvatarUpload" @mouseenter="mouseenterClick3" @mouseleave="mouseleaveClick3">
						<i slot="default" class="el-icon-plus"></i>
					</el-upload> -->

          <el-upload
            ref="uploadJJ"
            :action="uploadImgUrl"
            :show-file-list="false"
            :limit="1"
            list-type="picture-card"
            :on-success="handleAvatarSuccess3"
            :before-upload="beforeAvatarUpload"
            v-loading="loadingGSJJ"
            :on-progress="handleProgress3"
          >
            <div
              class="upload_img"
              @mouseenter="mouseenterClick3"
              @mouseleave="mouseleaveClick3"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <span
                v-if="imageUrl && isShowDeleteIcon_gongsi"
                class="el-upload-action"
                @click.stop="handleRemove3()"
              >
                <i class="el-icon-delete"></i>
              </span>
              <i v-if="!imageUrl" slot="default" class="el-icon-plus"></i>
            </div>
          </el-upload>

          <!-- <el-upload class="avatar-uploader" :action="uploadImgUrl"
						:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="imageUrl" :src="imageUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload> -->
        </div>
      </div>

      <!-- 提交按钮 -->
      <div class="info_item" @click="submitClick(1)" v-if="shenheStatus == 0">
        <div class="save">{{ $t("home.submit") }}</div>
      </div>

      <!-- 修改按钮 -->
      <div class="info_item" @click="submitClick(2)" v-if="shenheStatus == 2">
        <div class="change">{{ $t("release.changeInfo") }}</div>
      </div>
    </div>

    <!-- 提交弹窗 -->
    <div class="success_dialog">
      <el-dialog title="" :visible.sync="releaseSuccessVisible" width="30%">
        <div class="release_success">
          <div class="success_img">
            <img src="@/assets/mycenter/release_success.png" />
          </div>
          <div class="success_title" v-if="show_title == 1">
            {{ $t("release.submitSuccess") }}
          </div>
          <div class="success_title" v-if="show_title == 2">
            {{ $t("release.changeSuccess") }}
          </div>
          <div class="success_tip" v-if="show_title == 1">
            {{ $t("release.waitPatient") }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { uploadImgUrl } from "@/common/utils/config.js";

export default {
  data() {
    return {
      options: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
            {
              value: "daohang",
              label: "导航",
              children: [
                {
                  value: "cexiangdaohang",
                  label: "侧向导航",
                },
                {
                  value: "dingbudaohang",
                  label: "顶部导航",
                },
              ],
            },
          ],
        },
        {
          value: "zujian",
          label: "组件",
          children: [
            {
              value: "basic",
              label: "Basic",
              children: [
                {
                  value: "layout",
                  label: "Layout 布局",
                },
                {
                  value: "color",
                  label: "Color 色彩",
                },
                {
                  value: "typography",
                  label: "Typography 字体",
                },
                {
                  value: "icon",
                  label: "Icon 图标",
                },
                {
                  value: "button",
                  label: "Button 按钮",
                },
              ],
            },
            {
              value: "form",
              label: "Form",
              children: [
                {
                  value: "radio",
                  label: "Radio 单选框",
                },
                {
                  value: "checkbox",
                  label: "Checkbox 多选框",
                },
                {
                  value: "input",
                  label: "Input 输入框",
                },
                {
                  value: "input-number",
                  label: "InputNumber 计数器",
                },
                {
                  value: "select",
                  label: "Select 选择器",
                },
                {
                  value: "cascader",
                  label: "Cascader 级联选择器",
                },
                {
                  value: "switch",
                  label: "Switch 开关",
                },
                {
                  value: "slider",
                  label: "Slider 滑块",
                },
                {
                  value: "time-picker",
                  label: "TimePicker 时间选择器",
                },
                {
                  value: "date-picker",
                  label: "DatePicker 日期选择器",
                },
                {
                  value: "datetime-picker",
                  label: "DateTimePicker 日期时间选择器",
                },
                {
                  value: "upload",
                  label: "Upload 上传",
                },
                {
                  value: "rate",
                  label: "Rate 评分",
                },
                {
                  value: "form",
                  label: "Form 表单",
                },
              ],
            },
            {
              value: "data",
              label: "Data",
              children: [
                {
                  value: "table",
                  label: "Table 表格",
                },
                {
                  value: "tag",
                  label: "Tag 标签",
                },
                {
                  value: "progress",
                  label: "Progress 进度条",
                },
                {
                  value: "tree",
                  label: "Tree 树形控件",
                },
                {
                  value: "pagination",
                  label: "Pagination 分页",
                },
                {
                  value: "badge",
                  label: "Badge 标记",
                },
              ],
            },
            {
              value: "notice",
              label: "Notice",
              children: [
                {
                  value: "alert",
                  label: "Alert 警告",
                },
                {
                  value: "loading",
                  label: "Loading 加载",
                },
                {
                  value: "message",
                  label: "Message 消息提示",
                },
                {
                  value: "message-box",
                  label: "MessageBox 弹框",
                },
                {
                  value: "notification",
                  label: "Notification 通知",
                },
              ],
            },
            {
              value: "navigation",
              label: "Navigation",
              children: [
                {
                  value: "menu",
                  label: "NavMenu 导航菜单",
                },
                {
                  value: "tabs",
                  label: "Tabs 标签页",
                },
                {
                  value: "breadcrumb",
                  label: "Breadcrumb 面包屑",
                },
                {
                  value: "dropdown",
                  label: "Dropdown 下拉菜单",
                },
                {
                  value: "steps",
                  label: "Steps 步骤条",
                },
              ],
            },
            {
              value: "others",
              label: "Others",
              children: [
                {
                  value: "dialog",
                  label: "Dialog 对话框",
                },
                {
                  value: "tooltip",
                  label: "Tooltip 文字提示",
                },
                {
                  value: "popover",
                  label: "Popover 弹出框",
                },
                {
                  value: "card",
                  label: "Card 卡片",
                },
                {
                  value: "carousel",
                  label: "Carousel 走马灯",
                },
                {
                  value: "collapse",
                  label: "Collapse 折叠面板",
                },
              ],
            },
          ],
        },
        {
          value: "ziyuan",
          label: "资源",
          children: [
            {
              value: "axure",
              label: "Axure Components",
            },
            {
              value: "sketch",
              label: "Sketch Templates",
            },
            {
              value: "jiaohu",
              label: "组件交互文档",
            },
          ],
        },
      ],
      uploadImgUrl: uploadImgUrl,

      userInfor: "",

      headImg: "", //头像
      pickerDate: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      }, //控制选择日期范围

      isFlag: false,

      isShowDeleteIcon: false,
      isShowDeleteIcon_gongsi: false,
      birthday: "",
      user_nickname: "",
      sex: "",

      wechat: "", //微信号
      whatsApp: "", //WhatsApp
      whats_code: "", //whatsApp区号
      contacts: "", //联系人
      province_id: "", //省id
      province_name: "", //省name
      addr_id: "", //市id
      addr_name: "", //市name
      area_id: "", //区id
      area_name: "", //区名称
      company_name: "", //公司名称
      company_tax_number: "", // 公司税号
      broker_license_number: "", //经纪人执照号
      agent_type: "", // 代理类型
      company_address: "", //公司地址
      business_hours: "", // 营业时间
      Web_value: "", //web
      Skype_value: "", //Skype
      Facebook_value: "", //Facebook
      Twitter_value: "", //Twitter
      ins_value: "", //ins
      Linkedin_value: "", //Linkedin
      imageUrl: "", //公司简介图片
      whats_App: "", //公司whatsApp
      id_card: "", //身份证号
      company_desc: "", //公司简介

      countryList: [], //国家列表
      areaCodeList: [], //区号列表
      areaCode: "",

      areaVal: "", //选中的地区

      user_email: "", //邮箱号
      code: "", //邮箱验证码
      isGetCode: false, //是否获取到验证码
      time: 60 * 10,
      timer: "",

      mobile: "", //手机号
      mobile_code: "", //手机号区号

      company_logo: "", //公司logo
      company_logo_url: "", //公司logourl

      is_show: 1, //1是个人信息，2是公司信息
      releaseSuccessVisible: false, //提交成功弹框

      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      companyInforList: "",
      geren_desc: "", //个人介绍
      shenheStatus: "0", //1 审核中 2 审核通过 3 审核失败
      agent_type_list: [], //代理类型列表
      agent_type_id: "",
      lang: "",

      show_title: 1,

      loadingGS: false,
      loadingGSJJ: false,
      loadingGR: false,
    };
  },
  created() {
    this.lang = localStorage.getItem("lang");

    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", (e) => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值

      if (e.key === "lang") {
        this.lang = e.newValue;

        console.log(this.lang);
      }
    });
  },
  mounted() {
    this.getType();
    this.getCountry();
    this.init();
  },
  activated() {
    this.$nextTick(() => {
      this.getcompanyInfor();
    });
  },
  watch: {
    lang(newval) {
      console.log(newval, 123);

      if (newval) {
        console.log(newval);
        this.getcompanyInfor();
      }
    },
  },
  methods: {
    change(e) {
      console.log(e, 111111);
      this.agent_type_id = e;
    },
    // 个人信息
    person() {
      this.is_show = 1;
      this.getUserInfo();
    },
    company() {
      this.is_show = 2;
      this.getcompanyInfor();
    },
    async init() {
      await this.getUserInfo();
      await this.getAreaCode();
    },

    // 获取验证码
    getCode() {
      if (!this.user_email) {
        this.$message.error(this.$t("tips.emailNumberCannotBeEmpty"));
        return;
      }

      let data = {
        type: 7,
        is_test: 0,
        user_email: this.user_email,
      };

      this.$http
        .getCode(data)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(this.$t("sendSuccess"));
            this.isGetCode = true;
            this.timer = setInterval(() => {
              if (this.time == 1) {
                clearInterval(this.timer);
                this.time = 60 * 10;
                this.isGetCode = false;
              }
              this.time--;
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((v) => {});
    },
    // 获取区号
    getAreaCode() {
      return new Promise((resolve, reject) => {
        this.$http
          .getAreaCode()
          .then((res) => {
            if (res.code == 1) {
              this.areaCodeList = res.data;
              if (!this.whats_code) {
                this.whats_code = res.data[0];
              }
              if (!this.mobile) {
                this.mobile_code = res.data[0];
              }
            } else {
              this.$message.error(res.data.msg);
            }
            resolve();
          })
          .catch((v) => {
            console.log(v);
          });
      });
    },
    // 区号点击
    areaCodeClick(command) {
      this.whats_code = command;
    },
    // 区号点击
    areaCodeClick2(command) {
      this.mobile_code = command;
    },
    // 获取国家
    getCountry() {
      this.$http.getCountry().then((res) => {
        if (res.code == 1) {
          this.countryList = res.data;
          console.log(this.countryList);
          let newCountryList;
          newCountryList = this.countryList.map((item) => {
            return {
              label: item.name,
              value: item.id,
              children: item.children.map((item2) => {
                return {
                  label: item2.name,
                  value: item2.id,
                  children: item2.children.map((item3) => {
                    return {
                      label: item3.name,
                      value: item3.id,
                    };
                  }),
                };
              }),
            };
          });
          this.countryList = JSON.parse(JSON.stringify(newCountryList));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    cascaderChangeClick(val, type) {
      console.log(val);
      console.log(type);
      switch (type * 1) {
        case 1:
          console.log(val);
          if (val.length > 0) {
            this.province_id = val[0]; //省id
            this.addr_id = val[1]; //市id
            this.area_id = val[2]; //分类id，
          } else {
            this.province_id = "";
            this.addr_id = "";
            this.area_id = "";
          }
          break;
      }
    },
    // 获取会员信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        this.$http.userInfo().then((res) => {
          if (res.code == 1) {
            this.userInfor = res.data;
            console.log(this.userInfor, "个人信息");
            this.birthday = this.userInfor.birthday;
            this.headImg = this.userInfor.head_img;
            this.user_nickname = this.userInfor.user_nickname;
            this.sex = this.userInfor.sex ? this.userInfor.sex : 0;
            this.wechat = this.userInfor.wechat;
            this.whats_code = this.userInfor.whats_code;
            this.whatsApp = this.userInfor.whats_app; //WhatsApp
            this.contacts = this.userInfor.contacts; //联系人
            this.province_id = this.userInfor.province_id; //省id
            this.province_name = this.userInfor.province_name; //省name
            this.addr_id = this.userInfor.addr_id; //市id
            this.addr_name = this.userInfor.addr_name; //市name
            this.area_id = this.userInfor.area_id; //区id
            this.area_name = this.userInfor.area_name; //区名称
            this.company_name = this.userInfor.company_name; //公司名称
            // this.geren_desc = this.userInfor.company_desc; //身份证号
            this.company_desc = this.userInfor.company_desc; //公司简介
            this.areaVal = [this.province_id, this.addr_id, this.area_id];
            console.log(this.areaVal);

            this.user_email = this.userInfor.user_email;
            this.mobile = this.userInfor.mobile;
            this.mobile_code = this.userInfor.mobile_code;
            this.company_logo = this.userInfor.company_logo;
            this.company_logo_url = this.userInfor.company_logo_url;
          } else if (res.code == -201) {
          } else {
            this.$message.error(res.msg);
          }

          resolve();
        });
      });
    },
    //鼠标进入事件
    mouseenterClick() {
      if (this.headImg) {
        this.isShowDeleteIcon = true;
      }
    },
    // 鼠标离开事件
    mouseleaveClick() {
      if (this.headImg) {
        this.isShowDeleteIcon = false;
      }
    },
    handleRemove() {
      this.headImg = "";
      this.$refs.uploadGR.clearFiles();
    },

    //鼠标进入事件
    mouseenterClick3() {
      if (this.imageUrl) {
        this.isShowDeleteIcon_gongsi = true;
      }
    },
    // 鼠标离开事件
    mouseleaveClick3() {
      if (this.imageUrl) {
        this.isShowDeleteIcon_gongsi = false;
      }
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    handleRemove3(file) {
      console.log(1234);
      console.log(file, 3);
      this.imageUrl = "";
      this.$refs.uploadJJ.clearFiles();
    },
    handleAvatarSuccess3(res, file) {
      console.log(1234);
      this.imageUrl = file.response.data[0].path;
      console.log(file);
      console.log(this.imageUrl);
      this.loadingGSJJ = false;
    },
    handleProgress3() {
      this.loadingGSJJ = true;
    },

    handleAvatarSuccess(res, file) {
      console.log(file);
      // this.$set(this.userInfor, 'head_img', file.response.data[0].path)
      this.headImg = file.response.data[0].path;
      this.loadingGR = false;
    },
    handleProgress() {
      this.loadingGR = true;

    },

    // 删除logo
    handleRemove2() {
      console.log(2);
      this.company_logo = "";
      this.company_logo_url = "";
      this.$refs.upload.clearFiles();
    },
    // 上传logo
    handleAvatarSuccess2(res, file) {
      console.log("哈哈哈");
      console.log(file);
      this.company_logo = file.response.data[0].id;
      this.company_logo_url = file.response.data[0].path;
      console.log(this.company_logo_url, "头像");
      this.loadingGS = false;
    },

    handleProgress2() {
      this.loadingGS = true;
    },

    // 上传之前
    beforeAvatarUpload(file) {
      console.log("jhhhh");
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      console.log("文件类型", fileType);

      if (
        fileType === ".jpg" ||
        fileType === ".png" ||
        fileType === ".jpeg" ||
        fileType === ".bmp" ||
        fileType === ".gif"
      ) {
      } else {
        this.$message.error(this.$t("pleaseUploadTheCorrectImageType"));
        return false;
      }
    },
    // 个人信息保存
    saveClick() {
      if (!this.headImg) {
        this.$message.error(this.$t("uploadPhoto"));
        return;
      }
      // // 邮箱验证
      // if (this.user_email != '') {
      // 	let emailText = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
      // 	if (!emailText.test(this.user_email)) {
      // 		this.$message.error(this.$t('tips.validateUserEmail'))
      // 		return false;
      // 	}

      // }
      if (this.wechat != "") {
        let regular = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
        if (!regular.test(this.wechat)) {
          this.$message.error(this.$t("tips.WeChatRegular"));
          return false;
        }
      }
      // if (!this.user_email) {
      // 	this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'))
      // 	return;
      // }

      // if (!this.code) {
      // 	this.$message.error(this.$t('tips.codeCannotBeEmpty'))
      // 	return;
      // }

      let data = {
        head_img: this.headImg,
        sex: this.sex,
        birthday: this.birthday,
        user_nickname: this.user_nickname,
        wechat: this.wechat, //微信号
        whats_code: this.whats_code,
        whats_app: this.whatsApp, //个人WhatsApp
        type: 7,
        user_email: this.user_email, //邮箱
        code: this.code,
        contacts: this.contacts, //联系人
        province_id: this.areaVal[0], //省id
        addr_id: this.areaVal[1], //市id
        area_id: this.areaVal[2], //区id
        company_name: this.company_name, //公司名称
        id_card: this.id_card, //Id，
        // company_desc: this.geren_desc, //公司简介
        mobile: this.mobile,
        mobile_code: this.mobile_code,
        company_logo: this.company_logo_url,
      };
      this.$http.edit_user_info(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          let newUserinfo = {
            ...this.$store.state.userinfo,
            user_nickname: this.userInfor.user_nickname,
          };
          this.$store.commit("edituserinfo", newUserinfo);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 公司信息提交
    submitClick(type) {
      this.show_title = type;
      console.log(this.agent_type);
      console.log("提交");

      let data = {
        company_logo: this.company_logo_url, // 公司logo
        company: this.company_name, // 公司名称
        company_tax: this.company_tax_number, //公司税号
        name: this.contacts, //联系人
        mobile: this.mobile, // 联系电话
        prefix: this.whats_code, //区号
        email: this.user_email, //邮箱
        license_number: this.broker_license_number, //经纪人执照号
        type: this.agent_type_id, //代理类型
        province_id: this.province_id, //省ID
        city_id: this.addr_id, //市id
        district_id: this.area_id, //区id
        whatsapp: this.whatsApp, //whatsapp
        work_time: this.business_hours, //营业时间
        web: this.Web_value,
        skype: this.Skype_value,
        facebook: this.Facebook_value,
        twitter:this.Twitter_value,
        instagram: this.ins_value,
        linkedin: this.Linkedin_value,
        content: this.company_desc, //公司简介
        content_img: this.imageUrl, //公司简介图片
      };

      console.log(data, "上传data");

      this.$http.companyInfor(data).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.releaseSuccessVisible = true;
        } else {
          this.$message.info(res.msg);
        }
      });
    },

    // 获取公司信息
    getcompanyInfor() {
      this.$http.getcompanyInfor().then((res) => {
        if (res.code == 1) {
          this.companyInforList = res.data;
          console.log(this.companyInforList, "公司信息");
          this.company_logo_url = this.companyInforList.company_logo; // 公司logo
          console.log(this.company_logo_url, "公司logo");
          this.company_name = this.companyInforList.company; // 公司名称
          this.company_tax_number = this.companyInforList.company_tax; //公司税号
          this.contacts = this.companyInforList.name; //联系人
          this.mobile = this.companyInforList.mobile; // 联系电话
          this.whats_code = this.companyInforList.prefix; //区号
          this.user_email = this.companyInforList.email; //邮箱
          this.broker_license_number = this.companyInforList.license_number; //经纪人执照号
          this.agent_type = this.companyInforList.type_name; //代理类型
          this.agent_type_id = this.companyInforList.type; //代理类型的id
          this.province_id = this.companyInforList.province_id; //省ID
          this.addr_id = this.companyInforList.city_id; //市id
          this.area_id = this.companyInforList.district_id; //区id
          this.company_address = [this.province_id, this.addr_id, this.area_id]; //公司地址
          console.log(this.companyAddress);
          this.whats_App = this.companyInforList.whatsapp; //whatsapp
          this.business_hours = this.companyInforList.work_time; //营业时间
          this.Web_value = this.companyInforList.web;
          this.Skype_value = this.companyInforList.skype;
          this.Facebook_value = this.companyInforList.facebook;
          this.Twitter_value = this.companyInforList.twitter;
          this.ins_value = this.companyInforList.instagram;
          this.Linkedin_value = this.companyInforList.linkedin;
          this.company_desc = this.companyInforList.content; //公司简介
          this.imageUrl = this.companyInforList.content_img; //公司简介图片

          this.shenheStatus = this.companyInforList.status || 0; //审核状态
        } else {
          this.$message.info(res.msg);
        }
      });
    },

    getType() {
      this.$http.getType().then((res) => {
        if (res.code == 1) {
          console.log(res.data, 1234);
          this.agent_type_list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped="" lang="less">
.person_infor {
  width: 100%;
}

.login_top {
  display: flex;
  padding: 24px 40px;
  border-bottom: 1px solid #f3f5f6;

  .img {
    display: flex;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .border_box {
    display: flex;
    flex-direction: column;
    position: relative;

    .login_title {
      margin-left: 8px;
      margin-right: 64px;
      font-weight: bold;
      font-size: 18px;

      &:hover {
        cursor: pointer;
      }
    }

    .border {
      position: absolute;
      bottom: -15px;
      left: 23px;
      width: 40px;
      height: 3px;
      background: rgba(243, 152, 0);
      border-radius: 2px;
    }

    .showColor {
      color: rgba(243, 152, 0) !important;
    }
  }
}

.status_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 16px 24px;
  width: 912px;
  // height: 48px;
  background: #f6f7f9;
  opacity: 1;
  border-radius: 4px;
  text-align: left;
  // line-height: 48px;
  box-sizing: border-box;

  .status_box_left {
    .status_title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }

    .now_status {
      font-size: 16px;
      font-weight: bold;

      .ing {
        color: #2d91ff;
      }

      .error {
        color: #e60012;
      }
    }
  }

  .status_btn {
    display: flex;
    align-items: center;
    font-size: 14px;

    .submit {
      text-align: center;
      line-height: 36px;
      background: #f39800;
      font-size: 16px;
      color: #ffffff;
      width: 104px;
      height: 36px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.infor {
  padding-left: 80px;
  width: 100%;
  margin: 0 auto;
  margin-top: 24px;

  .infor_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
    font-size: 14px;

    .infor_item_left {
      width: 80px;
      margin-right: 32px;
      text-align: left;
    }

    .infor_item_right {
      display: flex;

      .input {
      }
    }
  }
}

.info_item {
  padding-bottom: 36px;
}

.save {
  margin-top: 54px;
  text-align: center;
  line-height: 48px;
  background: #f39800;
  font-size: 16px;
  color: #ffffff;
  width: 300px;
  height: 48px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
}

.change {
  margin-top: 54px;
  text-align: center;
  line-height: 48px;
  background: #fff;
  font-size: 16px;
  color: #f39800;
  width: 300px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #f39800;

  &:hover {
    cursor: pointer;
  }
}

.upload_img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    border-radius: 50%;
    width: 110px;
    height: 110px;
  }
}

/* 选中后的字体颜色 */
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #f39800 !important;
}

/* 选中后小圆点的颜色 */
/deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #f39800 !important;
  border-color: #f39800 !important;
}

/deep/.el-radio {
  &:hover {
    border-color: #f39800 !important;
    color: #f39800 !important;

    //鼠标滑过时小圆点边框显示
    .el-radio__inner {
      border-color: #f39800;
    }
  }

  .el-radio__inner {
    // 鼠标滑过小圆点时的样式
    &:hover {
      border-color: #f39800;
    }
  }
}

/deep/ .el-upload--picture-card {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
}

/deep/ .el-upload__tip {
  color: #4177dd;
}

/deep/ .el-input {
  width: 800px;
}

.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.el-dropdown-menu {
  // width: 100px;
  max-height: 150px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.whatsApp {
  display: flex;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  width: 800px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  padding: 0 15px;
  cursor: pointer;

  .whatsapp_input {
    // margin-left: 10px;

    input {
      width: 700px;
      color: #606266;
      border: none;
      outline: none;
    }
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #c8cbd1;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c8cbd1;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c8cbd1;
}

input::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #c8cbd1;
}

.code {
  position: absolute;
  right: 36px;
  color: #4177dd;
  font-size: 14px;

  .code1 {
    &:hover {
      cursor: pointer;
    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 180px;
  line-height: 180px;
  text-align: center;
  border: 1px dashed #666;
}

.avatar {
  width: 146px;
  height: 146px;
  display: block;
}

.success_dialog {
  /deep/ .el-dialog__body {
    // width: 480px;
    // height: 436px;
    border-radius: 8px;
    overflow-y: hidden;
  }
}

.release_success {
  padding-top: 56px;
  width: 100%;
  height: 416px;
  box-sizing: border-box;

  .success_img {
    img {
      width: 88px;
      height: 88px;
    }
  }

  .success_title {
    margin: 32px 0;
    font-weight: bold;
    color: #333333;
    font-size: 20px;
  }

  .success_tip {
    margin: 0 auto;
    color: #666666;
    width: 404px;
    word-break: break-all;
  }
}

.jianjie_box {
  div {
    display: flex;
  }

  /deep/.upload_img {
    img {
      width: 146px !important;
      height: 146px !important;
      border-radius: 0 !important;
    }
  }

  /deep/.el-upload-action:hover {
    font-size: 20px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0 !important;
  }

  /deep/.el-upload--picture-card {
    width: 146px;
    height: 146px;
    border: 1px dashed #ddd !important;
  }
}
</style>
